import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const leshommes = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Les hommes" />
    <h3 className='underline-title'>Les hommes</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Ils ne font pas attention<br />
        Ils croient que c'est le début<br />
        Ils sont convaincus que c'est la fin<br />
        Ils ne se détachent pas d'eux-mêmes<br />
        Ils sont trop attachés à eux-mêmes<br />
        Ils ne se dédoublent que rarement<br />
        Ou alors quelques un seulement<br />
        Ils sont malheureux d'eux-mêmes<br />
        Ils refusent ce qui leur est destiné<br />
        Cherchant ce qui ne leur appartient pas<br />
        Ils ne veulent pas souffir<br />
        Faisant tout pour souffir<br />
        Ils font souffir<br />
        C'est ça l'homme<br />
        Ce sont les hommes<br />
        </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 26 mars 1976</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default leshommes
